@charset "utf-8";

$primary: rgb(0, 141, 54);

// Set the global font.
@import "../assets/fonts/OpenSans-Regular.ttf";
$family-serif: "OpenSans", sans-serif;
$family-primary: $family-serif;
// Globally remove the focus highlight.
$button-focus-box-shadow-size: 0;
$button-focus-border-color: black;
$input-focus-box-shadow-size: 0;
$input-focus-border-color: black;

//Import animate.css
@import "~animate.css/animate.min.css";

//Import bulma-tooltip
@import "~@creativebulma/bulma-tooltip/dist/bulma-tooltip.min.css";

.modal,
.tile,
canvas,
.table,
#welcome-dropdown {
  animation: fadeIn;
  animation-duration: 200ms;
}

.navbar-menu {
  animation: fadeIn;
  animation-duration: 300ms;
}

.active-process {
  animation: flash-active 1s infinite;
}

@keyframes flash-active {
  0% {
    box-shadow: 0 0 10px #fafafa;
  }
  50% {
    box-shadow: 0 0 10px rgb(0, 141, 54);
  }
  100% {
    box-shadow: 0 0 10px #fafafa;
  }
}

// Globally hide the scrollbar.
html {
  overflow: auto !important;
}

// Alignment of time markers above the schedule.
th {
  border: 0 !important;
  min-width: 50px !important;
}

th > h6 {
  display: inline-block !important;
  transform: translateX(-50%) !important;
}

.block-counter {
  border: 0 !important;
  vertical-align: bottom !important;
  h6 {
    transform: translateY(+80%) !important;
  }
}

.has-dark-hover:hover {
  background-color: #008031 !important;
}

#root {
  position: relative;
  min-height: 100vh;
}

#page-body {
  padding-bottom: 13rem;
}

#page-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10rem !important;
}

// Change selector arrow to be black.

.select:not(.is-multiple):not(.is-loading)::after,
.navbar-link:not(.is-arrowless)::after {
  border-color: #000000 !important;
}

// Setup custom scrollbar.

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(90, 90, 90, 0.35);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(90, 90, 90, 0.5);
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}

// Animations

@keyframes fade-in {
  0% {
    background-color: transparent;
  }
  100% {
    background-color: #ff4136;
  }
}

// Import Bulma SASS files.
@import "./node_modules/bulma/bulma.sass";
